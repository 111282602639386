import React from "react";
import { Link } from "react-router-dom";
import { PRODUCT_LIST } from "../../constant/Product";

const RenderProductList = () => {
  return PRODUCT_LIST.map(({ id, img, name, slug, dis }) => (
    <div
      key={id}
      className="p-2 bg-gradient-to-r from-violet-200 to-blue-200 rounded-xl shadow-lg transition-transform duration-200 hover:scale-105"
    >
      <Link to={slug}>
        <img
          src={img}
          alt={name}
          className="w-80 h-80 object-cover object-top"
          loading="lazy"
        />
      </Link>
      <div className="rounded-lg m-5" style={{ color: "#922C87" }}>
        <h3 className="text-2xl text-center font-bold">{name}</h3>
        <h3 className="text-xl text center font-medium">{dis}</h3>
      </div>
    </div>
  ));
};

export default RenderProductList;
