import React from "react";
import brand1 from "../../../image/Icecreambrand/brand1.png";
import brand2 from "../../../image/Icecreambrand/brand2.png";

const products = [
  { img: brand1, name: "Brand 1" },
  { img: brand2, name: "Brand 2" }
];

const Brand = () => {
  return (
    <div>
      <div className="rounded-lg mt-10 bg-[#fc0fc0]">
        <div className="text-center pt-6" style={{ color: "#FDFEFE" }}>
          <span className="text-4xl lg:text-6xl font-bold">Brands</span>
        </div>
        <div className="flex flex-wrap justify-center mb-2 py-3 px-5 gap-4">
          {products.map((product) => (
            <div className="col-12 md:col-6 lg:col-3" key={product.name}>
              <img
                src={product.img}
                alt={product.name}
                className="object-contain rounded-lg w-full h-full mb-2" // Change to object-contain for maintaining aspect ratio
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Brand;
