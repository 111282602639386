import React, { useEffect } from "react";
import "./Hero.css";
import MasterImage from "../../image/icecreamBG.jpg";
import RollingImage from "../../image/masterHomeice.png";

const Hero = ({
  id = "home",
  image = MasterImage,
  header = "",
  decs = "Discover our wide range of services and products, including icecream, leather goods, freezer boxes, and interior solutions.",
}) => {
  useEffect(() => {
    document.getElementById(id).scrollIntoView({ behavior: "instant" });
  }, [id]);

  return {
    ...(header ? (
      <section
        className="relative w-full h-full overflow-hidden rounded-lg"
        id={id}
      >
        <img
          src={image} // Replace with your image URL
          alt="Hero"
          className="hero-bg h-screen bg-cover bg-no-repeat w-full lg:h-auto lg:bg-cover"
        />
        <div className="absolute inset-0 bg-black opacity-50" />

        <div className="absolute inset-0 flex flex-col items-center justify-center text-white text-center">
          <h1 className="text-4xl lg:text-8xl font-bold mb-4">
            Welcome to{" "}
            <span className="animate-pulse">{`Bluehides ${header}`}</span>
          </h1>
          <p className="text-lg lg:text-2xl mb-6 px-4">{decs}</p>
          <a
            href="https://wa.me/917448843331?text=Hello%20Bluehides,%20I%20need%20more%20information%20about%20the%20franchise%20and%20other%20services%20included%20in%20your%20company!"
            className="bg-pink-600 text-white font-bold rounded transition duration-300 py-2 px-4"
          >
            Get Started
          </a>
        </div>
      </section>
    ) : (
      <section
        className="relative w-full h-full overflow-hidden rounded-lg"
        id={id}
      >
        <img
          src={image} // Replace with your image URL
          alt="Hero"
          className="hero-bg h-screen bg-cover bg-no-repeat w-full lg:h-auto lg:bg-cover"
        />
        <div className="absolute inset-0 bg-black opacity-50" />
        <div className="absolute inset-0 flex flex-col items-center justify-center text-white text-center">
          <div className="grid grid-nogutter">
            <div className="col-12 md:col-6 p-6 text-center md:text-left flex align-items-center">
              <section>
                <h1 className="text-2xl lg:text-7xl font-bold mb-4">
                  Welcome to{" "}
                  <span className="animate-pulse">{`Bluehides ${header}`}</span>
                </h1>
                <p className="text-lg lg:text-2xl mb-6">{decs}</p>
                <a
                  href="https://wa.me/917448843331?text=Hello%20Bluehides,%20I%20need%20more%20information%20about%20the%20franchise%20and%20other%20services%20included%20in%20your%20company!"
                  className="bg-pink-600 text-white font-bold rounded transition-transform duration-200 hover:scale-105 p-3"
                >
                  Get Started
                </a>
              </section>
            </div>
            <div className="col-12 md:col-6 overflow-hidden">
              <img
                src={RollingImage}
                alt="render about us"
                className="md:w-2/3 m-auto image"
              />
            </div>
          </div>
        </div>
      </section>
    )),
  };
};

export default Hero;
