import React, { useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./Header.css";
import { Button } from "primereact/button";
import { Menu } from "primereact/menu";
import { navbarItems } from "../../constant/Header";
import logo from "../../image/logo.png";

function Header({ isopen, toggle }) {
  const menu = useRef(null);
  const navigate = useNavigate();

  const onHandleScroll = (type) => {
    const REF = {
      Home: "home",
      About: "about",
      Enquiry: "enquiry",
      Contact: "contact",
    };

    const element = document.getElementById(REF[type]);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    } else {
      const homeElement = document.getElementById('home');
      if (homeElement) {
        homeElement.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  const items = [
    {
      items: [
        { label: "IceCream", command: () => navigate("/icecream") },
        { label: "Leather", command: () => navigate("/leather") },
        { label: "Freezer", command: () => navigate("/freezer") },
        { label: "Interior", command: () => navigate("/interior") },
      ],
    },
  ];

  return (
    <header className="fixed">
      <Link to="/" className="link">
        <img src={logo} width={"40px"} alt="header-logo" className="flex justify-start" />
        <h1 className="text-3xl ">BlueHides</h1>
      </Link>

      <div className="menu-items">
        {navbarItems.map((item) => (
          <>
            {item.title === "Product" && (
              <Menu
                key={item.title}
                model={items}
                popup
                ref={menu}
                id="popup_menu"
              />
            )}
            <Button
              link
              className="link"
              key={item.title}
              aria-controls="popup_menu"
              aria-haspopup
              onClick={(event) => {
                if (item.title === "Product") {
                  menu.current.toggle(event);
                } else {
                  navigate("/");
                  onHandleScroll(item.title);
                }
              }}
            >
              {item.title}
            </Button>
          </>
        ))}
      </div>
      <div className="icons">
        <div className="mobile-menu-icon flex justify-end mr-4" style={{ color: '#922C87'}}>
          <Button
            icon={`pi ${isopen ? "pi-times" : "pi-bars"}`}
            rounded
            text
            aria-label="Cancel"
            onClick={toggle}
          />
        </div>
      </div>
    </header>
  );
}

export default Header;
