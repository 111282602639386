import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";
import logo from "../../image/footerlogo.png";

function Footer() {
  const onHandleScroll = (type) => {
    const element = document.getElementById(type);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    } else {
      const homeElement = document.getElementById("home");
      if (homeElement) {
        homeElement.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  return (
    <footer className="bottom-0 footer-content border-round" id="contact">
      <div className="grid mt-2 gap-2">
        <div className="col-12 lg:col-4">
          <div className="h-full">
            <div
              className="p-3 h-full flex flex-column"
              style={{ borderRadius: "6px" }}
            >
              <div className="font-medium text-xl mb-2">
                <img src={logo} alt="hero-1" className="md:w-32 lg:w-48" />
              </div>
              <div className="text-lg footerfont">
                BlueHides offers exceptional ice cream, luxurious leathers, and
                innovative freezer boxes. We craft unique flavors that delight
                the senses while our premium leathers enhance the experience.
                Enjoy indulgence and freshness with our cutting-edge freezer
                solutions!
              </div>
              <ul className="flex gap-5 mt-10 list-none ml-0 justify-center">
                <li>
                  <a
                    href="https://www.facebook.com/share/4AkAVjR73WtnFn8s/"
                    target="blank"
                    className="icon-hover"
                  >
                    <i className="pi pi-facebook p-2.5 rounded-full text-xl sm:text-2xl md:text-3xl lg:text-2xl text-black"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/icespot_tnss?igsh=MTBqNzJpbWc4OHZmcw=="
                    target="blank"
                    className="icon-hover"
                  >
                    <i className="pi pi-instagram p-2.5 rounded-full text-xl sm:text-2xl md:text-3xl lg:text-2xl text-black"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://wa.me/917448843331?text=Hello%20Bluehides,%20I%20need%20more%20information%20about%20the%20franchise%20and%20other%20services%20included%20in%20your%20company!"
                    target="blank"
                    className="icon-hover"
                  >
                    <i className="pi pi-whatsapp p-2.5 rounded-full text-xl sm:text-2xl md:text-3xl lg:text-2xl text-black"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.youtube.com/@BluehidesIcespot"
                    target="blank"
                    className="icon-hover"
                  >
                    <i className="pi pi-youtube p-2.5 rounded-full text-xl sm:text-2xl md:text-3xl lg:text-2xl text-black"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-2">
          <div className="p-3 h-full">
            <div
              className="p-3 h-full flex flex-column"
              style={{ borderRadius: "6px" }}
            >
              <div className="font-bold text-2xl mb-4">GET IN TOUCH</div>
              <ul className="list-none p-0 m-0 flex-grow-1 text-xl font-bold mt-2">
                <li className="flex items-center mb-4">
                  <i className="pi pi-map-marker text-[#FDFEFE] font-bold mr-2 text-2xl sm:text-2xl md:text-2xl"></i>
                  <span className="text-lg">Below BlueStone Jewellery RKV <br />Road, Erode - 638003</span>
                </li>
                <li className="flex items-center mb-4">
                  <a href="tel:+919790036786" className="no-underline flex items-center">
                    <i className="pi pi-phone text-[#FDFEFE] font-bold mr-2 text-2xl sm:text-3xl md:text-2xl"></i>
                    <span className="text-lg">9790036786</span>
                  </a>
                </li>
                <li className="flex items-center mb-4">
                  <a href="tel:+917448843331" className="no-underline flex items-center">
                    <i className="pi pi-phone text-[#FDFEFE] font-bold mr-2 text-2xl sm:text-3xl md:text-2xl"></i>
                    <span className="text-lg">7448843331</span>
                  </a>
                </li>
                <li className="flex items-center mb-4">
                  <a href="mailto:support@bulehides.com" className="no-underline flex items-center">
                    <i className="pi pi-envelope text-[#FDFEFE] font-medium mr-2 text-2xl sm:text-3xl md:text-2xl"></i>
                    <span className="text-lg">support@bulehides.com</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="grid col-span-1 mt-1">
          <div className="  h-full">
            <div
              className="p-3 h-full flex flex-column"
              style={{ borderRadius: "6px" }}
            >
              <div className=" font-bold text-2xl mb-4">USEFUL LINKS</div>
              <ul className="font-bold list-none p-0 m-0 flex-grow-1 text-xl mt-2">
                <li className="flex align-items-center mb-4">
                  <i className="text-[#FDFEFE] mr-2 font-medium"></i>
                  <Link
                    to="/"
                    className="no-underline text-lg"
                    onClick={() => onHandleScroll("home")}
                  >
                    Home
                  </Link>
                </li>
                <li className="flex align-items-center mb-4">
                  <i className="text-[#FDFEFE] mr-2 font-medium"></i>
                  <Link
                    to="/"
                    className="no-underline text-lg"
                    onClick={() => onHandleScroll("about")}
                  >
                    About
                  </Link>
                </li>
                <li className="flex align-items-center mb-4">
                  <i className="text-[#FDFEFE] mr-2 font-medium"></i>
                  <Link
                    to="/"
                    className="no-underline text-lg"
                    onClick={() => onHandleScroll("enquiry")}
                  >
                    Enquiry
                  </Link>
                </li>
                <li className="flex align-items-center mb-4">
                  <i className="text-[#FDFEFE] mr-2 font-medium"></i>
                  <Link
                    to="/"
                    className="no-underline text-lg"
                    onClick={() => onHandleScroll("contact")}
                  >
                    Contact Us
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="grid col-span-1 gap-10 mt-1">
          <div className=" h-full">
            <div
              className="p-3 h-full flex flex-column"
              style={{ borderRadius: "6px" }}
            >
              <div className="font-bold text-2xl mb-4">PRODUCT</div>
              <ul className="font-bold list-none p-0 m-0 flex-grow-1 text-xl mt-2">
                <li className="flex align-items-center mb-4">
                  <i className="text-[#FDFEFE] mr-2 font-medium"></i>
                  <Link to="/icecream" className="no-underline text-lg">
                    Ice Cream
                  </Link>
                </li>
                <li className="flex align-items-center mb-4">
                  <i className="text-[#FDFEFE] mr-2 font-medium"></i>
                  <Link to="/leather" className="no-underline text-lg">
                    Leather
                  </Link>
                </li>
                <li className="flex align-items-center mb-4">
                  <i className="text-[#FDFEFE] mr-2 font-medium"></i>
                  <Link to="/freezer" className="no-underline text-lg">
                    Icebox Freezer
                  </Link>
                </li>
                <li className="flex align-items-center mb-4">
                  <i className="text-[#FDFEFE] mr-2 font-medium"></i>
                  <Link to="/interior" className="no-underline text-lg">
                    Interior
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="grid col-span-1 gap-10 mt-1">
          <div className=" h-full">
            <div
              className="p-3 h-full flex flex-column"
              style={{ borderRadius: "6px" }}
            >
              <div className="font-bold text-2xl mb-4">MAIN LOCATION</div>
              <ul className="font-bold list-none p-0 m-0 flex-grow-1 text-xl mt-2">
                <li className="flex align-items-center mb-4">
                  <i className="text-[#FDFEFE] mr-2 font-medium"></i>
                  <span className="text-lg">Erode</span>
                </li>
                <li className="flex align-items-center mb-4">
                  <i className="text-[#FDFEFE] mr-2 font-medium"></i>
                  <span className="text-lg">Coimbatore</span>
                </li>
                <li className="flex align-items-center mb-4">
                  <i className="text-[#FDFEFE] mr-2 font-medium"></i>
                  <span className="text-lg">Chennai</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <button />
      </div>
      <div className="text-center p-3 bg-[#922C87] text-white">
        <p className="text-base font-medium">
          © 2024 BlueHides. All rights reserved. Designed By : <a href="https://astralgrid.in/" target="_blank" class="hover:underline">Astral Grid</a>
        </p>
      </div>
    </footer>
  );
}

export default Footer;
