const navbarItems = [
  {
    title: "Home",
  },
  {
    title: "About",
  },
  {
    title: "Product",
  },
  {
    title: "Enquiry",
  },
  {
    title: "Contact",
  },
];

const productIce = [
  {
    name: "Choco Lava Bliss",
    description:
      "Rich chocolate ice cream with molten fudge swirls and chocolate chunks.",
    price: 4.99,
    link:"icecreambox"
  },
  {
    name: "Vanilla Dream",
    description:
      "Classic creamy vanilla ice cream made with real vanilla beans.",
    price: 3.99,
  },
  {
    name: "Strawberry Fields",
    description:
      "Fresh strawberry ice cream packed with chunks of ripe strawberries.",
    price: 4.49,
  },
  {
    name: "Minty Fresh",
    description:
      "Cool mint ice cream with a hint of chocolate chips for added crunch.",
    price: 4.49,
  },
  {
    name: "Cookie Dough Delight",
    description:
      "Vanilla ice cream loaded with cookie dough pieces and chocolate chips.",
    price: 5.49,
  },
];
const productLeather = [
  {
    name: "Classic Leather Jacket",
    description:
      "Timeless black leather jacket with a sleek fit and vintage appeal.",
    price: 199.99,
  },
  {
    name: "Genuine Leather Wallet",
    description:
      "Handcrafted wallet made from premium leather with multiple card slots.",
    price: 49.99,
  },
  {
    name: "Leather Backpack",
    description:
      "Durable leather backpack with a spacious interior and padded straps.",
    price: 129.99,
  },
  {
    name: "Suede Leather Boots",
    description:
      "Stylish suede boots with a comfortable fit and rugged sole.",
    price: 89.99,
  },
  {
    name: "Leather Messenger Bag",
    description:
      "Elegant messenger bag made of soft leather, perfect for work or travel.",
    price: 74.99,
  },
];


const productFreezer = [
  {
    name: "Ultra Chill Chest Freezer",
    description:
      "Spacious 7 cu. ft. chest freezer with adjustable temperature settings.",
    price: 299.99,
  },
  {
    name: "Compact Upright Freezer",
    description:
      "Efficient 3.5 cu. ft. upright freezer perfect for small spaces.",
    price: 249.99,
  },
  {
    name: "Smart Wi-Fi Freezer",
    description:
      "Advanced freezer with Wi-Fi connectivity and temperature monitoring app.",
    price: 499.99,
  },
  {
    name: "Energy Star Rated Freezer",
    description:
      "Energy-efficient model with a 5-year warranty and ample storage.",
    price: 399.99,
  },
  {
    name: "Portable Mini Freezer",
    description:
      "Lightweight 1.1 cu. ft. portable freezer ideal for camping or road trips.",
    price: 179.99,
  },
];
const productInterior = [
  {
    name: "Modern Area Rug",
    description:
      "Stylish area rug with a geometric design, perfect for any living space.",
    price: 149.99,
  },
  {
    name: "Leather Accent Chair",
    description:
      "Chic leather chair with a comfortable cushion and sleek metal legs.",
    price: 299.99,
  },
  {
    name: "Wooden Coffee Table",
    description:
      "Rustic wooden coffee table with a natural finish and ample surface space.",
    price: 199.99,
  },
  {
    name: "Decorative Wall Mirror",
    description:
      "Elegant round mirror with a gold frame, ideal for enhancing any room.",
    price: 89.99,
  },
  {
    name: "Table Lamp",
    description:
      "Contemporary table lamp with a fabric shade and adjustable brightness.",
    price: 59.99,
  },
];

export { navbarItems, productIce, productLeather, productFreezer, productInterior };
