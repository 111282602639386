import React, { forwardRef, useState } from "react";
import emailjs from "emailjs-com";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import MasterEnquery from "../../image/Cute woman looking at question marks through magnifying glass.png";

const Enquery = forwardRef(({ image = MasterEnquery }) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    whatsappNumber: "",
    message: "",
    Services: "",
    city: "",
  });

  const [formErrors, setFormErrors] = useState({});

  // Function to validate email format
  const validateEmail = (email) => {
    return /\S+@\S+\.\S+/.test(email);
  };

  // Function to validate phone number (must be 10 digits for simplicity)
  const validatePhoneNumber = (number) => {
    return /^\d{10}$/.test(number);
  };

  // Function to handle input changes
  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
    setFormErrors({ ...formErrors, [id]: "" }); // Clear error on change
  };

  // Function to validate form before submission
  const validateForm = () => {
    const errors = {};
    if (!formData.name) errors.name = "Name is required";
    if (!formData.email) {
      errors.email = "Email is required";
    } else if (!validateEmail(formData.email)) {
      errors.email = "Email is not valid";
    }
    if (!formData.phoneNumber) {
      errors.phoneNumber = "Phone number is required";
    } else if (!validatePhoneNumber(formData.phoneNumber)) {
      errors.phoneNumber = "Phone number must be 10 digits";
    }
    if (!formData.whatsappNumber) {
      errors.whatsappNumber = "WhatsApp number is required";
    } else if (!validatePhoneNumber(formData.whatsappNumber)) {
      errors.whatsappNumber = "WhatsApp number must be 10 digits";
    }
    if (!formData.message) errors.message = "Message is required";
    if (!formData.Services) errors.Services = "Please select a service";

    if (formData.Services === "IceCream" && !formData.city) {
      errors.city = "City is required for starting a franchise";
    }

    return errors;
  };

  // Function to handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return; // Stop submission if there are validation errors
    }

    emailjs
      .send(
        "service_oydxujy",
        "template_igq34ah",
        {
          name: formData.name,
          email: formData.email,
          phoneNumber: formData.phoneNumber,
          whatsappNumber: formData.whatsappNumber,
          message: formData.message,
          services: formData.Services,
          city: formData.city,
        },
        "-Wz7U5-IihviRlYki"
      )
      .then((response) => {
        console.log("Email successfully sent!", response.status, response.text);
        setFormData({
          name: "",
          email: "",
          phoneNumber: "",
          whatsappNumber: "",
          message: "",
          Services: "",
          city: "",
        });
      })
      .catch((error) => {
        console.error("Failed to send email. Error: ", error);
      });
  };

  const renderForm = (
    <div className="flex align-items-center justify-content-center">
      <div className="surface-card p-4 shadow-2 border-round w-full lg:w-8">
        <div className="text-center mb-5">
          <div className="text-3xl font-bold text-color mb-3">Enquiry page</div>
        </div>
        <form onSubmit={handleSubmit}>
          {formErrors.name && (
            <small className="p-error">{formErrors.name}</small>
          )}
          <label
            htmlFor="name"
            className="block font-bold mb-2"
            style={{ color: "#922C87" }}
          >
            Name
          </label>
          <InputText
            id="name"
            type="text"
            placeholder="Enter your name"
            className="w-full mb-3 border-2 p-2"
            style={{ color: "#922C87" }}
            value={formData.name}
            onChange={handleChange}
          />

          {formErrors.email && (
            <small className="p-error">{formErrors.email}</small>
          )}
          <label
            htmlFor="email"
            className="block font-bold mb-2"
            style={{ color: "#922C87" }}
          >
            Email
          </label>
          <InputText
            id="email"
            type="text"
            placeholder="Enter your email"
            className="w-full mb-3 border-2 p-2"
            style={{ color: "#922C87" }}
            value={formData.email}
            onChange={handleChange}
          />

          {formErrors.Services && (
            <small className="p-error">{formErrors.Services}</small>
          )}
          <label
            htmlFor="Services"
            className="block mb-2 font-bold"
            style={{ color: "#922C87" }}
          >
            Select a Service
          </label>
          <select
            id="Services"
            value={formData.Services}
            onChange={handleChange}
            className="w-full mb-3 border-2 p-2"
            style={{ borderRadius: "7px", color: "#922C87" }}
          >
            <option value="">Choose a Service</option>
            <option value="IceCream">IceCream</option>
            <option value="Freezer">Freezer</option>
            <option value="Leather">Leather</option>
            <option value="Interior">Interior</option>
          </select>

          {/* Conditionally render city input if service is IceCream */}
          <>
            {formErrors.city && (
              <small className="p-error">{formErrors.city}</small>
            )}
            {formData.Services === "IceCream" && (
              <>
                <label
                  htmlFor="city"
                  className="block font-bold mb-2"
                  style={{ color: "#922C87" }}
                >
                  City
                </label>
                <InputText
                  id="city"
                  type="text"
                  placeholder="Name the city that you want to start franchise"
                  className="w-full mb-3 border-2 p-2"
                  style={{ color: "#922C87" }}
                  value={formData.city}
                  onChange={handleChange}
                />
              </>
            )}
          </>

          {formErrors.phoneNumber && (
            <small className="p-error">{formErrors.phoneNumber}</small>
          )}
          <label
            htmlFor="phoneNumber"
            className="block font-bold mb-2"
            style={{ color: "#922C87" }}
          >
            Phone Number
          </label>
          <InputText
            id="phoneNumber"
            type="text"
            placeholder="Phone Number"
            className="w-full mb-3 border-2 p-2"
            style={{ color: "#922C87" }}
            value={formData.phoneNumber}
            onChange={handleChange}
          />

          {formErrors.whatsappNumber && (
            <small className="p-error">{formErrors.whatsappNumber}</small>
          )}
          <label
            htmlFor="whatsappNumber"
            className="block font-bold mb-2"
            style={{ color: "#922C87" }}
          >
            WhatsApp Number
          </label>
          <InputText
            id="whatsappNumber"
            type="text"
            placeholder="WhatsApp Number"
            className="w-full mb-3 border-2 p-2"
            style={{ color: "#922C87" }}
            value={formData.whatsappNumber}
            onChange={handleChange}
          />

          {formErrors.message && (
            <small className="p-error">{formErrors.message}</small>
          )}
          <label
            htmlFor="message"
            className="block font-bold mb-2"
            style={{ color: "#922C87" }}
          >
            Message
          </label>
          <InputTextarea
            id="message"
            placeholder="Your message"
            className="w-full mb-3 border-2 p-2"
            style={{ color: "#922C87" }}
            value={formData.message}
            onChange={handleChange}
          />

          <Button
            label="Submit"
            raised
            severity="info"
            className="text-50 w-full p-2"
            style={{ background: "#c93d82" }}
          />
        </form>
      </div>
    </div>
  );

  return (
    <section id="enquiry">
      <div
        className="grid grid-nogutter text-800 p-4 mb-3 mt-6 border-round"
        style={{ color: "#FDFEFE" }}
      >
        <div className="col-12 md:col-6 flex md:justify-start md:items-center overflow-hidden">
      <img
        src={image}
        alt="hero-1"
        className="md:ml-0 block md:h-75" // Use md:ml-0 to reset margin on larger screens
      />
    </div>
        <div className="col-12 md:col-6">
          <section className="p-4" style={{ color: "#922C87" }}>
            <span className="block text-6xl text-center font-bold mb-4 leading-tight">
              Join the Bluehides Family!
            </span>
            <p className="mt-0 mb-4 text-center text-xl font-bold line-height-3">
              Our customer service department would love to talk to you. Let us
              know how we can help!
            </p>
            {renderForm}
          </section>
        </div>
      </div>
    </section>
  );
});

export default Enquery;
