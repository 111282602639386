import React from "react";
import Hero from "../../Hero";
import LeatherHome from "../../../image/LeatherHome.jpeg";
import ProductHome from "../ProductHome";
import Enquery from "../../Enquery";
import Footer from "../../Footer";
import { LEATHER } from "../../../constant/Product";

const Leather = () => {
  return (
    <>
      <Hero
        {...{
          id: "Leather",
          image: LeatherHome,
          header: "Leather",
          decs: "Explore our finest collection of premium leather goods.",
        }}
      />
      <ProductHome {...{ productArray: LEATHER, bgColor: "bg-[#7c2d12]" }} />
      <Enquery
        {...{
          image: "https://dt-leathery.myshopify.com/cdn/shop/files/grid01_e9e6b4f5-228d-4d49-8755-ccbb07da5486.jpg?v=1654080379",
          bgColor: "#7d583b",
        }}
      />
      <Footer />
    </>
  );
};

export default Leather;
