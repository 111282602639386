import React, { useState } from "react";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import Header from "./components/Header";
import Sidebar from "./components/Sidebar";
import MasterHome from "./components/MasterHome";
import IceCream from "./components/Products/IceCream";
import Freezer from "./components/Products/Freezer";
import Leather from "./components/Products/Leather";
import Interior from "./components/Products/Interior";

function App() {
  const [isopen, setisopen] = useState(false);
  const toggle = () => setisopen(!isopen);
 
  return (
    <div className="App">
      <Header {...{ isopen, toggle }} />
      <Sidebar isopen={isopen} toggle={toggle} />
      <div className="content">
        <Routes>
          <Route index path="/" element={<MasterHome />} />
          <Route index path="/icecream" element={<IceCream />} />
          <Route index path="/leather" element={<Leather />} />
          <Route index path="/freezer" element={<Freezer />} />
          <Route index path="/interior" element={<Interior />} />
        </Routes>
      </div>
    </div>
  );
}

export default App;
