import HardTop from "../image/HardTop.png";
import GlassTop from "../image/FC-Ice-Cream-Freezer.png";
import AlphansoMango from "../image/AlphansoMango.png";
import ArabianCockTail from "../image/ArabianCockTail.png";
import Avacado from "../image/Avacado.png";
import BlueBerry from "../image/BlueBerry.png";
import ColdCoffee from "../image/ColdCoffee.png";
import Chocolate from "../image/Chocolate.png";
import Grapes from "../image/Grapes.png";
import GreenApple from "../image/GreenApple.png";
import JackFruit from "../image/JackFruit.png";
import Kiwi from "../image/Kiwi.png";
import LotusBiscoff from "../image/LotusBiscoff.png";
import Mango from "../image/Mango.png";
import Mojito from "../image/Mojito.png";
import Oreo from "../image/Oreo.png";
import PassionFruit from "../image/PassionFruit.png";
import Payasam from "../image/Payasam.png";
import WaterMelon from "../image/WaterMelon.png";
import CafeInterior from "../image/CafeInterior.jpg";
import GroseryInterior from "../image/GroseryInterior.jpg";
import IcecreamInterior from "../image/IcecreamInterior.jpg";
import Bakery from "../image/Bakery.jpg";
import SplitLeather from "../image/SplitLeather.jpg";
import GenuineLeather from "../image/GenuineLeather.jpg";
import TopGrainLeather from "../image/TopGrainLeather.jpg";
import FullGrainLeather from "../image/FullGrainLeather.jpg";
import WetBlueHides from "../image/WetBlueHides.jpeg";
import RawHides from "../image/RawHides.jpeg";
import PULeather from "../image/PULeather.jpg";
import Product2 from "../image/leather.jpeg";
import Product4 from "../image/blue-and-white-modular-kitchen-design.jpg";
import IceCream  from "../image/IceCream_logo.png";

const FREEZER = [
  {
    name: "Hard Top",
    img: HardTop,
    disc: "Durable & insulated",
    bgColor: "#F5B7B1",
  },
  {
    name: "Glass Top",
    img: GlassTop,
    disc: "Clear & easy access",
    bgColor: "#F5B7B1",
  },
];

const ICE_CREAM = [
  {
    name: "Alphonso Mango",
    img: AlphansoMango,
    disc: "Discover the unique essence of Alphonso mango.",
    bgColor: "#F5B7B1",
  },
  {
    name: "Arabian Cocktail",
    img: ArabianCockTail,
    disc: "Savor a blend of exotic flavors.",
    bgColor: "#F5B7B1",
  },
  {
    name: "Avocado",
    img: Avacado,
    disc: "Creamy and refreshing avocado delight.",
    bgColor: "#F5B7B1",
  },
  {
    name: "BlueBerry",
    img: BlueBerry,
    disc: "Experience the frosty burst of blueberries.",
    bgColor: "#F5B7B1",
  },
  {
    name: "Cold Coffee",
    img: ColdCoffee,
    disc: "A refreshing journey of coffee pleasure.",
    bgColor: "#F5B7B1",
  },
  {
    name: "Chocolate",
    img: Chocolate,
    disc: "Unwrap the happiness of chocolate ice cream.",
    bgColor: "#F5B7B1",
  },
  {
    name: "JackFruit",
    img: JackFruit,
    disc: "Enjoy the tropical flavor of jackfruit.",
    bgColor: "#F5B7B1",
  },
  {
    name: "Kiwi",
    img: Kiwi,
    disc: "Feel the vibrant freshness of kiwi.",
    bgColor: "#F5B7B1",
  },
  {
    name: "Lotus Biscoff",
    img: LotusBiscoff,
    disc: "Unlock the creamy joy of Biscoff.",
    bgColor: "#F5B7B1",
  },
  {
    name: "Oreo",
    img: Oreo,
    disc: "Let Oreo flavor take your taste buds flying.",
    bgColor: "#F5B7B1",
  },
  {
    name: "Payasam",
    img: Payasam,
    disc: "Indulge in the traditional Payasam essence.",
    bgColor: "#F5B7B1",
  },
  {
    name: "WaterMelon",
    img: WaterMelon,
    disc: "Cool down with sweet watermelon flavor.",
    bgColor: "#F5B7B1",
  },
  {
    name: "Grapes",
    img: Grapes,
    disc: "Relish the juicy essence of grapes.",
    bgColor: "#F5B7B1",
  },
  {
    name: "Mojito",
    img: Mojito,
    disc: "Refreshing minty mojito for a cool treat.",
    bgColor: "#F5B7B1",
  },
  {
    name: "Mango",
    img: Mango,
    disc: "Discover the bold flavor of ripe mango.",
    bgColor: "#F5B7B1",
  },
  {
    name: "Passion Fruit",
    img: PassionFruit,
    disc: "Tropical passion fruit in every bite.",
    bgColor: "#F5B7B1",
  },
  {
    name: "Green Apple",
    img: GreenApple,
    disc: "Crisp and refreshing green apple taste.",
    bgColor: "#F5B7B1",
  },
];

const ICE_CREAM_MENU = {
  premium: [
    { name: "Saffron", price: 60 },
    { name: "Pistachio", price: 60 },
    { name: "Lotus Biscoff", price: 55 },
    { name: "Blueberry", price: 55 },
    { name: "Alphonso Mango", price: 55 },
    { name: "Rose", price: 50 },
    { name: "Rasamalai", price: 50 },
    { name: "Snikers", price: 50 },
    { name: "Kitkat", price: 50 },
    { name: "Palada Payasam", price: 40 },
    { name: "Cold Coffee", price: 40 },
    { name: "Gulab Jamun", price: 40 },
  ],
  gold: [
    { name: "Fig", price: 35 },
    { name: "English Delight", price: 35 },
    { name: "Tender Coconut", price: 35 },
    { name: "Semiya Payasam", price: 35 },
    { name: "Custard Apple", price: 35 },
    { name: "Chocolate", price: 35 },
    { name: "Malai Kulfi", price: 35 },
  ],
  waterCandy: [
    { name: "Kiwi", price: 25 },
    { name: "Watermelon", price: 20 },
    { name: "Mango", price: 20 },
    { name: "Pine Apple", price: 20 },
    { name: "Mojito", price: 20 },
    { name: "Pink Guava", price: 20 },
    { name: "Green Apple", price: 20 },
    { name: "Orange", price: 15 },
    { name: "Mosambi", price: 10 },
  ],
  silver: [
    { name: "Oreo", price: 30 },
    { name: "Jackfruit", price: 30 },
    { name: "Strawberry", price: 30 },
    { name: "Avocado", price: 30 },
    { name: "Chikku", price: 30 },
    { name: "Cherry", price: 30 },
    { name: "Pinachikka", price: 30 },
    { name: "White Guava", price: 25 },
    { name: "Naruneendi", price: 25 },
  ],
};

const INTERIOR = [
  {
    name: "Grosery",
    img: GroseryInterior,
    disc: "Fresh & efficient design",
    bgColor: "#F5B7B1",
  },
  {
    name: "Cafe",
    img: CafeInterior,
    disc: "Inviting & modern atmosphere",
    bgColor: "#F5B7B1",
  },
  {
    name: "Icecream",
    img: IcecreamInterior,
    disc: "Fun & cool ambiance",
    bgColor: "#F5B7B1",
  },
  {
    name: "Bakery",
    img: Bakery,
    disc: "Warm & rustic charm",
    bgColor: "#F5B7B1",
  },
];

const LEATHER = [
  {
    name: "Raw Hides",
    img: RawHides,
    disc: "High-quality, untreated leather.",
    bgColor: "#F5B7B1",
  },
  {
    name: "Wet Blue Hides",
    img: WetBlueHides,
    disc: " Its flexibility and resilience.",
    bgColor: "#F5B7B1",
  },
  {
    name: "Split Leather",
    img: SplitLeather,
    disc: "Durable, economical leather option.",
    bgColor: "#F5B7B1",
  },
  {
    name: "Genuine Leather",
    img: GenuineLeather,
    disc: "Authentic leather with lasting quality.",
    bgColor: "#F5B7B1",
  },
  {
    name: "Top-Grain Leather",
    img: TopGrainLeather,
    disc: "Soft, refined, high-quality finish.",
    bgColor: "#F5B7B1",
  },
  {
    name: "Full-Grain Leather",
    img: FullGrainLeather,
    disc: "Premium, natural, and durable leather.",
    bgColor: "#F5B7B1",
  },
  {
    name: "PU Leather",
    img: PULeather,
    disc: "Affordable, synthetic leather alternative.",
    bgColor: "#F5B7B1",
  },
];

const PRODUCT_LIST = [
  {
    id: 1,
    img: IceCream,
    name: "Ice Cream",
    slug: "/icecream",
    dis: "Unique flavors, delightful",
    bgColor: "#F5B7B1",
  },
  {
    id: 1,
    img: Product2,
    name: "Leather",
    slug: "/leather",
    dis: "Luxurious, premium quality",
    bgColor: "#F5B7B1",
  },
  {
    id: 1,
    img: GlassTop,
    name: "Icebox Freezer",
    slug: "/freezer",
    dis: "Innovative, freshness",
    bgColor: "#F5B7B1",
  },
  {
    id: 1,
    img: Product4,
    name: "Interior",
    slug: "/interior",
    dis: "Stylish, comfort-enhancing",
    bgColor: "#F5B7B1",
  },
];
export { FREEZER, ICE_CREAM, ICE_CREAM_MENU, INTERIOR, LEATHER, PRODUCT_LIST };
