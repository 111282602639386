import React from "react";

const ProductHome = ({ productArray }) => {
 
  return (
    <div className="p-10 mb-10 rounded-lg mt-10" style={{ color: "#922C87" }}>
      <div>
        <h1
          className="text-center text-7xl font-bold"
          style={{ color: "#946F8C" }}
        >
          Our<span className="text-pink"> Classic </span> Favorites
        </h1>
        <h2 className="text-center font-normal text-4xl mt-5 mb-5">
          Check out our top products that our customers love
        </h2>
      </div>
      <div className="grid flex justify-center align-center">
        {productArray.map((product) => (
          <div
            className="col-12 md:col-6 lg:col-3 inline-block rounded-xl p-4 m-3 bg-gradient-to-r from-violet-200 to-blue-200 "
            key={product.name}
            // style={{ background: product.bgColor }}
          >
            <div className="flex justify-center items-center">
              <img
                src={product.img}
                alt={product.name}
                className="object-cover rounded-lg"
                style={{ width: "250px", height: "250px" }}
              />
            </div>
            <div className="text-center">
              <h2 className="mt-10 text-2xl font-bold">{product.name}</h2>
              <h2 className="font-bold">{product.disc}</h2>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProductHome;
