import React from "react";
import Hero from "../../Hero";
import InteriorHome from "../../../image/InteriorHome.jpg";
import InteriorEnquiry from "../../../image/InteriorEnquiry.jpeg";
import ProductHome from "../ProductHome";
import Enquery from "../../Enquery";
import Footer from "../../Footer";
import { INTERIOR } from "../../../constant/Product";

const Interior = () => {
  return (
    <>
      <Hero
        {...{
          id: "Interior",
          image: InteriorHome,
          header: "Interior",
          decs: "Transform your space with our premium design solutions.",
        }}
      />
      <ProductHome {...{ productArray: INTERIOR, bgColor: "bg-[#d6d3d1]" }} />
      <Enquery {...{ image: InteriorEnquiry, bgColor: "#e5e5e5" }} />
      <Footer />
    </>
  );
};

export default Interior;
