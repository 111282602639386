import React from "react";
import RenderProductList from "./RenderProductList";

const ProductList = () => {
  return (
    <div
      className="p-4 mb-3 mt-6 rounded-lg text-white text-center" id="productlist"
    >
      <h1 className="text-7xl font-bold m-10" style={{ color: "#946F8C" }} >Our Products</h1>
      <p className="text-2xl font-bold m-5 pb-4" style={{ color: "#922C87" }} >
        We offer a wide range of high-quality products designed to meet your
        needs and exceed your expectations.
      </p>
      <div className="grid lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-1 gap-5 m-10 flex justify-center item-center">
        <RenderProductList />
      </div>
    </div>
  );
};

export default ProductList;
