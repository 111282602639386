import React from "react";
import Hero from "../../Hero";
import FreezerHome from "../../../image/FreezerHome.jpg";
import FreezerEnquiry from "../../../image/FreezerEnquiry.jpeg";
import ProductHome from "../ProductHome";
import Enquery from "../../Enquery";
import Footer from "../../Footer";
import { FREEZER } from "../../../constant/Product";

const Freezer = () => {
  return (
    <>
      <Hero
        {...{
          id: "Freezer",
          image: FreezerHome,
          header: "Freezer",
          decs: "Experience cutting-edge freezer solutions for all your storage needs.",
        }}
      />
      <ProductHome {...{ productArray: FREEZER, bgColor: "bg-[#e0f2fe]" }} />
      <Enquery {...{ image: FreezerEnquiry, bgColor: "#f0f9ff" }} />
      <Footer />
    </>
  );
};

export default Freezer;
