import Catlog_0 from "../../../image/Catlog_0.png";
import Catlog_1 from "../../../image/Catlog_1.jpeg";
import Catlog_2 from "../../../image/Catlog_2.jpeg";

const Catlog = () => {
    return (
        <div className="flex flex-col items-center overflow-hidden">
            <h1
                className="text-4xl lg:text-6xl font-bold mt-7 text-center"
                style={{ color: "#946F8C" }}
            >
                Details About Franchise
            </h1>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-5 w-full max-w-screen-lg flex justify-center item-center">
                <div className="flex justify-center">
                    <img 
                        src={Catlog_0} 
                        className="object-cover w-1/2 h-full" 
                        alt="Image 1" 
                    />
                </div>
                <div className="grid grid-cols-1 gap-6 flex justify-center item-center">
                    <div className="flex justify-center">
                        <img 
                            src={Catlog_1} 
                            className="object-cover w-full max-w-xs h-auto" 
                            alt="Image 2" 
                        />
                    </div>
                    <div className="flex justify-center">
                        <img 
                            src={Catlog_2} 
                            className="object-cover w-full max-w-xs h-auto" 
                            alt="Image 3" 
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Catlog;