import React from "react";
import myImage from "../image/franchisise.png";
import IceSpotMenu from "../image/doc/Icespot_Menu_Card.pdf"
import AlmondDairyMenu from "../image/doc/Almond_Menu_Card.pdf"

const FranChises = () => {
  return (
    <div className="rounded-lg mt-6 mb-6 text-center" id="franchises">
      <div className=" items-center p-4">
        <h1
          className="text-4xl lg:text-6xl font-bold"
          style={{ color: "#946F8C" }}
        >
          Franchise
        </h1>
        <h2 className="text-2xl mt-3" style={{ color: "#922C87" }}>
          Franchise Available In Select Locations In TamilNadu
        </h2>
      </div>
      <div className="flex items-center justify-center h-500 mb-3">
        <img
          src={myImage}
          alt="Description of the image"
           className="w-80 h-80 lg:w-1/2 lg:h-1/2 mx-auto"
        />
      </div>
      <div className="font-bold">
      <div className="relative flex justify-center items-center h-20"> {/* Adjust height as needed */}
        <h1 className="absolute text-2xl animate-heartbeat" style={{ color: "#922C87", willChange: "transform" }}>
          Download The Franchise Menu
        </h1>
      </div>
        <div className="flex justify-center items-center">
        <div className="p-4 grid grid-cols-2 gap-4">
          <a
            href={IceSpotMenu} download
            className="bg-pink-600 text-white font-bold rounded transition duration-300 p-3 text-center"
          >
            Ice Spot
          </a>
          <a
            href={AlmondDairyMenu} download
            className="bg-pink-600 text-white font-bold rounded transition duration-300 p-3 text-center"
          >
            Almond Dairy
          </a>
        </div>
        </div>
      </div>
    </div>
  );
};
export default FranChises;
