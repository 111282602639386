import React, { useEffect, useState } from "react";
import "./Sidebar.css";
import { Tree } from "primereact/tree";
import { useNavigate } from "react-router-dom";

const Sidebar = ({ isopen, toggle }) => {
  const navigate = useNavigate();
  const [nodes, setNodes] = useState([]);
  const [expandedKeys, setExpandedKeys] = useState({}); // Keep track of expanded nodes

  useEffect(() => {
    const NodeService = {
      getTreeNodesData() {
        return [
          {
            key: "0",
            label: "Home",
            data: "home",
          },
          {
            key: "1",
            label: "About",
            data: "about",
          },
          {
            key: "2",
            label: "Product",
            data: "Product",
            children: [
              {
                key: "2-0",
                label: "IceCream",
                data: "icecream",
              },
              {
                key: "2-1",
                label: "Leather",
                data: "leather",
              },
              {
                key: "2-2",
                label: "Freezer",
                data: "freezer",
              },
              {
                key: "2-3",
                label: "Interior",
                data: "interior",
              },
            ],
          },
          {
            key: "3",
            label: "Enquiry",
            data: "enquiry",
          },
          {
            key: "4",
            label: "Contact",
            data: "contact",
          },
        ];
      },

      getTreeNodes() {
        return Promise.resolve(this.getTreeNodesData());
      },
    };

    NodeService.getTreeNodes().then((data) => setNodes(data));
  }, []);

  let opacityClasses = ["sidebar-container"];
  if (isopen) {
    opacityClasses.push("opacity-on");
  } else {
    opacityClasses.push("opacity-off");
  }

  const onHandleScroll = (type) => {
    const element = document.getElementById(type);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    } else {
      const homeElement = document.getElementById("home");
      if (homeElement) {
        homeElement.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  const onNodeClick = ({ node }) => {
    if (node.children) {
      // Toggle expand/collapse for nodes with children (like "Product")
      let _expandedKeys = { ...expandedKeys };
      if (_expandedKeys[node.key]) {
        delete _expandedKeys[node.key]; // Collapse node
      } else {
        _expandedKeys[node.key] = true; // Expand node
      }
      setExpandedKeys(_expandedKeys);
    } else if (
      ["icecream", "leather", "freezer", "interior"].includes(node.data)
    ) {
      toggle();
      navigate(`/${node.data}`);
    } else {
      toggle();
      navigate("/");
      onHandleScroll(node.data);
    }
  };

  return (
    <div className={opacityClasses.join(" ")} isopen={isopen.toString()}>
      <div className="sidebar-wrapper">
        <div className="sidebar-menu">
          <Tree
            value={nodes}
            expandedKeys={expandedKeys} // Use expandedKeys to control node expansion
            onToggle={(e) => setExpandedKeys(e.value)} // Sync expanded state with the component
            onNodeClick={onNodeClick} // Custom node click handler
            className="w-full md:w-30rem m-0 p-0 sidebar-links"
            style={{ fontSize: "1.5rem", fontWeight: "700px" }}
          />
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
