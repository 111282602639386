import React from "react";
import Hero from "../../Hero";
import IceCreamimg from "../../../image/banner1.jpeg";
import Brand from "./Brand";
import Catlog from "./Catlog";
import ProductHome from "../ProductHome";
import MenuCard from "./MenuCard";
import Enquery from "../../Enquery";
import Footer from "../../Footer";
import { ICE_CREAM } from "../../../constant/Product";
import FranChises from "../../FranChises";
import Icecream from "../../../image/IceCream_logo.png";

const IceCream = () => {
  return (
    <>
      <Hero
        {...{
          id: "IceCream",
          image: IceCreamimg,
          header: "Ice Cream",
          decs: "Indulge in our delightful range of flavors and treats.",
        }}
      />
      <Catlog />
      <FranChises />
      <Brand />
      <ProductHome {...{ productArray: ICE_CREAM, bgColor: "bg-white" }} />
      <MenuCard />
      <Enquery
        {...{
          image: Icecream,
          bgColor: "#ecfccb",
        }}
      />
      <Footer />
    </>
  );
};

export default IceCream;
