import React from "react";
import { ICE_CREAM_MENU } from "../../../constant/Product";

const MenuCard = () => {
  return (
    <div className="container mx-auto bg-[#fafafa] p-5">
      <div className="pt-3">
        <h1 className="text-center text-7xl font-bold" style={{ color: "#946F8C" }}>
          Our Menu
        </h1>
      </div>

      {/* Menu Section */}
      <div className="grid flex justify-center mt-10 p-5">
        {/* PREMIUM Section */}
        <div className="col-12 md:col-6 xl:col-3 m-3 bg-blue-100 p-5 rounded-lg">
          <h2 className="text-xl font-bold mb-3">PREMIUM</h2>
          {ICE_CREAM_MENU.premium.map((product) => (
            <div key={product.name} className="flex justify-between">
              <span className="text-base font-medium">{product.name}</span>
              <span className="text-base font-bold">₹{product.price}</span>
            </div>
          ))}
        </div>

        {/* GOLD Section */}
        <div className="col-12 md:col-6 xl:col-3 m-3 bg-yellow-100 p-5 rounded-lg">
          <h2 className="text-xl font-bold mb-3">GOLD</h2>
          {ICE_CREAM_MENU.gold.map((product) => (
            <div key={product.name} className="flex justify-between">
              <span className="text-base font-medium">{product.name}</span>
              <span className="text-base font-bold">₹{product.price}</span>
            </div>
          ))}
        </div>

        {/* WATER CANDY Section */}
        <div className="col-12 md:col-6 xl:col-3 m-3 bg-green-100 p-5 rounded-lg">
          <h2 className="text-xl font-bold mb-3">WATER CANDY</h2>
          {ICE_CREAM_MENU.waterCandy.map((product) => (
            <div key={product.name} className="flex justify-between">
              <span className="text-base font-medium">{product.name}</span>
              <span className="text-base font-bold">₹{product.price}</span>
            </div>
          ))}
        </div>

        {/* SILVER Section */}
        <div className="col-12 md:col-6 xl:col-3 m-3 bg-purple-100 p-5 rounded-lg">
          <h2 className="text-xl font-bold mb-3">SILVER</h2>
          {ICE_CREAM_MENU.silver.map((product) => (
            <div key={product.name} className="flex justify-between">
              <span className="text-base font-medium">{product.name}</span>
              <span className="text-base font-bold">₹{product.price}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MenuCard;
