import React from "react";
import Hero from "./Hero";
import About from "./About";
import ProductList from "./ProductList";
import Enquery from "./Enquery";
import Footer from "./Footer";
import FranChises from "./FranChises";

function MasterHome() {
  return (
    <>
      <Hero />
      <ProductList />
      <FranChises />
      <About />
      <Enquery />
      <Footer />
    </>
  );
}

export default MasterHome;
