import React from "react";
import iceCrea from "../../image/2672335.png";

function About() {
  return (
    <section id="about" className="bg-[#FC0FC0]">
      <div className="grid grid-nogutter text-800">
        <div className="col-12 md:col-6 p-6 text-center md:text-left flex align-items-center ">
          <section>
            <span className="block text-6xl text-50 font-bold mb-3 ml-2">
              About Us
            </span>
            <p className="mt-0 mb-4 text-50 text-lg font-bold line-height-3 ml-2">
              At Bluehides, we are passionate about delivering exceptional
              products that enhance everyday experiences. Specializing in
              high-quality Ice Cream, Leather, Freezerbox, and Interior
              Solutions, we strive to bring innovation and satisfaction to our
              customers.
            </p>
            <div className="text-6xl text-50 font-bold mb-3">
              <div>
                <ul className="grid list-none m-0 p-0 footerfont text-base">
                  <li className="col-12 md:col-6 lg:col-6 mb-2">
                    <i className="pi pi-circle-fill mr-2"></i>
                    <span>Specializing in high-quality Ice Cream</span>
                  </li>
                  <li className="col-12 md:col-6 lg:col-6 mb-2">
                    <i className="pi pi-circle-fill mr-2"></i>
                    <span>Offering innovative Leather products</span>
                  </li>
                  <li className="col-12 md:col-6 lg:col-6 mb-2">
                    <i className="pi pi-circle-fill mr-2"></i>
                    <span>Providing reliable Freezerbox solutions</span>
                  </li>
                  <li className="col-12 md:col-6 lg:col-6 mb-2">
                    <i className="pi pi-circle-fill mr-2"></i>
                    <span>Expertise in Interior Solutions</span>
                  </li>
                </ul>
              </div>
            </div>
          </section>
        </div>
        <div className="col-12 md:col-6 overflow-hidden">
          <img
            src={iceCrea}
            alt="render about us"
            className="md:w-2/3 m-auto"
          />
        </div>
      </div>
    </section>
  );
}

export default About;
